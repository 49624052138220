@use 'sass:math';

// MIXINS (Reuseable CSS)
@mixin centerElement($widthOfElementInPixels, $heightOfElementInPixels) {
    position: absolute;
    top: 45%;
    left: 50%;
    margin: (-(math.div($widthOfElementInPixels, 2))) 0px 0px (-(math.div($heightOfElementInPixels,2)));
}

// Screen Size
$smallLaptopHeight: 790px;

// STYLING
.navBar {
    background-color: #1a76c7;
}

.dashboard-card {
    @include centerElement(528px, 715px);
    z-index: -1;

    &-user-editor {
        @include centerElement(625px, 950px);
        @media (max-width: 768px) {
            @include centerElement(550px, 550px);
        }
    }

    @media screen and (max-height: $smallLaptopHeight) {
        top: 54%;
    }
}

.devtool {
    &-card {
        &-user-editor {
            @media (max-width: 768px) {
                width: 550px;
                max-width: 550px;
                text-align: center;
            }

            width: 950px;
            max-width: 950px;
            text-align: center;
        }

        width: 715px;
        max-width: 715px;
        text-align: center;
    }

    &-page-layout {
        display: block;
        align-content: center;
        height: 100%;
    }

    &-title {
        margin-top: 4%;
        margin-bottom: 3%;
    }
}

.btn-toggle-theme {
    margin-top: 42%;
    margin-left: 90%;
}

#dropdown-basic-button {
    &-dark {
        width: auto !important;
    }
    &-light {
        width: auto !important;
    }
}
.fun {
    margin-left: 25px;
    margin-bottom: 10px;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.cul {
    &-card {
        display: flex;
        margin-left: 3%;
        margin-right: 3%;
    }

    &-db-dropdown {
        margin-right: 40px;
    }

    &-filter {
        margin-left: auto;
    }

    &-table-area {
        display: flex;
    }
}

.title {
    font-weight: bold;
    display: block;
    margin-bottom: 4px;
}

.tool-tip {
    &-block-dark {
        text-align: center;
        position: relative;
        padding: 10px;
        color: #f8f9fa;
        border-radius: 5px;
        border: 3px solid #1a76c7;
        background-color: #353b41;
    }

    &-block-light {
        text-align: center;
        position: relative;
        padding: 10px;
        color: #353b41;
        border-radius: 5px;
        border: 3px solid #1a76c7;
        background-color: #f8f9fa;
    }

    &-tip {
        display: block;
        height: 0px;
        width: 0px;
        border: 10px solid transparent;
        border-top-color: #1a76c7;
        position: absolute;
        bottom: -22px;
        left: calc(50% - 10px);
    }
}

.errorMessageContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    
    top: 20px;
    left: 0;
    right: 0;
    margin-left: 25%;
    margin-right: 25%;
    z-index: 999;

    animation: error-dropdown 500ms ease;

    @keyframes error-dropdown {
        from {
          transform: translateY(calc(-100vh));
        }
        to {
          transform: translateY(0);
        }
      }
}
