.dark-theme {
    background-color: black;
}

#dropdown-basic-button-dark {
    width: 160px;
}

// Client User Lookup 
.filter-form-dark {
    background-color: #6c757d !important;
    color: white !important;
}

// Client User Permissions Editor 
.clear-changes-link-dark {
    color:white;
    text-decoration-line: none;
    
    &:hover {
        color: white;
    }
}

.edited-permissions {
    &-granted-dark {
        color: rgb(0, 200, 0);
    }

    &-removed-dark {
        color: rgb(255, 72, 72);
    }
}

.submit-changes {
    &-header-dark {
        background-color: #212529 !important;
        border-color: #495057 !important;
        color: white;
    }

    &-body-dark {
        color: white;
    }
    &-footer-dark {
        border-color: #495057 !important;
    }        
}

.permissions-filter-form-dark {
    width: 170px !important;
}

// Impersonator
.username-lookup-form-dark {
    box-shadow: none !important;
}
