.edited-permissions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &-group {
        margin-left: 10px !important;
        margin-right: 10px !important;
        margin-bottom: 10px;
        padding: 0px !important;
        max-width: 400px !important;
    }    

    &-granted {
        max-width: 250px !important;
    }

    &-removed {
        max-width: 200px !important;
    }
}

.modal-body {
    max-height: 500px;
}