.permission-filter {
    &-header {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &-text {
            margin-left: 10px;
            margin-top: 5px;
        }
    }

    &-content {
        margin-bottom: 25px;
        &-cell {
            cursor: pointer;
        }
    }
}

#permission-dropdown {
    width: 100% !important;
} 

.permission-dropdown {
    &-item {
        width: auto !important;
    }
}