$devshackLogoSize: 500px;

.background {
    position: fixed;
    z-index: -20;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: 'flex';
}

.devshack-logo {
    position: absolute;
    top: 75px; 
    left: 45px;
    width: $devshackLogoSize;
    height: $devshackLogoSize;
}
