@mixin createRow() {
    text-align: center;
    display: flex;
    flex-direction: row;
}

.cupe-db-dropdown {
    margin-left: 0%;
    text-align: left !important;
}

.cupe-username-search {

    &-auth {
        margin-left: 5%;
        margin-right: 5%;
    }
    margin-left: 11%;
}

.username-title {
    @include createRow();
    direction: flex;
    
    .username-icon {
        margin-left: 5px;
    }
}

.cupe-search-btn {
    border-color: white !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.cupe-submit {
    position: sticky;
    margin-left: 750px;
    bottom: 0;
    width: 120px;
    
    background-color: #1a76c7;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;

    #cupe-submit-btn {
        margin-left: 0px;
        width: 120px;
    }
}

.cupe-permissions {
    margin-top: -20px;
    height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
}


.permission-scroll {
    height: 300px;
    margin-bottom: 20px;
    overflow-y: scroll;
}   

.permissions-filter {
    margin-left: auto;
    text-align: left !important;
}

.permissions-saved-message {
    color: rgb(0, 126, 0);
    font-weight: bold;
    margin-left: 13%;
    margin-top: 0px;
    margin-bottom: 0px !important;
    height: 35px;
    padding-top: 4px !important;
    padding-bottom: 0px !important;
}

.current-user-editing {
    margin-top: 15px;
}


.clear-changes-btn {
    margin-left: auto;
    margin-top: 15px;

    &:hover {
        color: white;
    }
}
