.light-theme {
    background-color: white;
}

// Client User Permissions Editor
.clear-changes-link-light {
    color:black;
    text-decoration-line: none;

    &:hover {
        color: black;
    }
}

.edited-permissions {
    &-granted-light {
        color: rgb(0, 150, 0);
    }

    &-removed-light {
        color: rgb(255, 29, 29);
    }
}

.permissions-filter-form-light {
    width: 170px !important;
    border-color: black !important;
}

//Used for ClientUserPermissionsWidget/Expanded
.checkbox-light {
    color: black;
}

.btn-close-light {
    background-color: #6c757d !important;
}

.button-light {
    border-color: black !important;
    
    &:hover {
        background-color: white !important;
        color: black !important;
    }
}

#dropdown-basic-button-light {
    width: 160px;

    &:hover {
        background-color: #e9ecef;
        color: black;
    }
}

// Login 
.login-button-light:hover {
    background-color: rgba(255, 255, 255, 0.744) !important;
    color: black !important;
}

// Impersonator 
.username-lookup-form-light {
    box-shadow: none !important;
    border-color: black !important;
}