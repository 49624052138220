.client-filter {
    box-shadow: none !important;
}

.copy-msg {
    color: green
}

.mouse-pointer {
    cursor: pointer;
}

.clientuser-header {
    direction: flex;
}

.header-title {
    flex: 1;
    margin-top: -15px;
}

.close-icon {
    margin-top: 5px;
    text-align: right;
}

.client-scroll {
    &-filter {
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 5px;
    }
    height: 200px;
    width: 250px;
    overflow-y: scroll;
}

.client-permission-filter {
    margin-top: 10px;
    cursor: pointer;
}

$spinnerSize: 56px;
.spinner {
    &-small {
        width: 25px;
        height: 25px;
        margin-top: 3px;
        margin-left: 60px;
        color: #1a76c7;
    }
    width: $spinnerSize !important;
    height: $spinnerSize !important;
    color: #1a76c7;
    margin-top: 10%;
    margin-bottom: 10%;
}

.clientusers-table {
    height: 250px;
    overflow: auto;
}

#filter-all-clients-label {
    margin-right: 80px !important;
    
}