@use '../App.scss';

.confirm-btn {
    margin-left: 25px;
    background: #1a76c7 !important;
}

.save-btn {
    text-align: right;
    background: #1a76c7 !important;
}

.close-btn {
    margin-top: -50px;
    margin-bottom: 10px;
    text-align: end;
}

.btn-close {
    box-shadow: none !important;
}