
#clientPermissionsCard{
    width: 100% !important;
    margin: 2% 0 2% 0;
}

@mixin displayCenter(){
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clientPermissionsTitle {
    &-isEdited {
        @include displayCenter();
        color: #1a76c7 !important;
    }

    &-notEdited {
        &-dark {
            @include displayCenter();
            color: white;
        }

        &-light {
            @include displayCenter();
            color: black;
        }
       
    }
}

.widgetChevron {
    &-light{
        margin-left: auto !important;
    }
    &-dark{
        margin-left: auto !important;
    }
}

.titleSpan{    
    &-light{
        margin-left: 5% !important;
    }
    &-dark{
        margin-left: 5% !important;
    }
}

.expandedBodyDiv {
    text-align: left;
}

.permission-row {

    display: flex;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.permission-column {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
    color:white;
}

.editButton {
    &-true {
        flex: 1;
        color: white !important;
        background-color: #1a76c7 !important;
        height: 30px !important;
        width: 60px !important;
        font-size: 12px !important;
        padding-bottom: 7px !important;
        margin-left: 5px;
        margin-top: 5px;
    }
    &-false {
        margin-left: 330px !important;
        color: white !important;
        background-color: #1a76c7 !important;
        height: 30px !important;
        width: 75px !important;
        font-size: 12px !important;
        padding-bottom: 7px !important;
        margin-top: 5px;
    }
}

.checkbox {
    &-light {
        margin: auto !important;
    }
    &-dark {
        margin: auto !important;
    }
}

.saveButton {
    flex: 1;
    color: white !important;
    background-color: #1a76c7 !important;
    height: 30px !important;
    width: 51px !important;
    font-size: 12px !important;
    margin-top: 5px;
    margin-right: 5px;
}

.btnDiv {
    display: flex;
    flex-direction: row;
}

.edited-permissions {
    border-width: 1px !important;
    border-color: red !important;
}

.edit-error-message {
    flex: 5;
    color: red !important;
    text-align: left;
    margin-top: 10px;
}

.edit-cancel {
    &-text-light {
        color: black;
        margin-top: 15px;
        margin-right: 10px;
        font-size: 14px !important;
    }

    &-text-dark {
        color: white;
        margin-top: 15px;
        margin-right: 10px;
        font-size: 14px !important;
    }
}

.checkbox {
    &-text-light {
        color: black;
    }

    &-text-dark {
        color: white;
    }
}