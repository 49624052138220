@mixin createRow() {
    text-align: center;
    display: flex;
    flex-direction: row;
}

.database-name-title {
    margin-bottom: 5px;
}

.impersonator {
    &-title {
        @include createRow();
        direction: flex;
        .title {
            margin-left: 90px;
        }
    }
    &-icon {
        margin-left: 5px;
    }
    &-sub-title {
        font-weight: bold;
        flex: 1; 
    }
    &-recent {
        @include createRow();
        direction: flex;
        .title {
            margin-left: 220px;
        }
    }
}

.impersonated {
    &-title {
        @include createRow();
        margin-bottom: -20px;
    }
    &-tooltip {
        margin-left: 1%;
    }
}

.lookup-btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.lookup-icon {
    color: grey;
}

.btn-primary {
    background-color: white !important;
}

.hint-text {
    font-weight: normal;
    margin-bottom: 5px;
}

.dropdown-position {
    position: fixed;
}

.username-lookup {
    &-card {
        width: 680px;
    }

    &-title {
        display: flex;
    }
}

.impersonationToken-div {
    text-align: center;
    margin-left: 250px;
}

.buttonGenerateToken {
    &-dark {
        color: white !important;
        background-color: gray;
        &-form:focus {
            box-shadow: none !important;
        }
    }

    &-light {
        color: black !important;
        background-color: white !important;
        &-form:focus {
            box-shadow: none !important;
        }
    }
   
}

.token-table {
    width: 660px !important;
    text-align: center !important;
    margin-left: -10px !important;
}

.token-cell {
    word-break: break-all !important;
}

.token-data {
    display: flex;
    flex-direction: row;
}

.copy-button {
    margin: 15px 7px 0 17px;
}

.recentOption {
    &-active {
        background-color: #1a76c7 !important;
        color: white;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 5px;
    }

    &-notActive {
        &-dark {
            background-color: #6c757d !important;
            color: white !important;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: 5px;
        }

        &-light {
            background-color: white !important;
            color: black !important;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-left: 5px;
        }
       
    }

    
   
}