.admin-page-scroll {
    height: 500px;
    overflow-y: scroll;
    margin-top: 10px;
}

.newUserDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.plusIcon {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.newImpersonatorTitle {
    cursor: pointer;
}

.newImpersonatorDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 16%;
    margin-right: 17%;
    padding: 8px;
    border: 2px solid;
    border-color: #1a76c7;
    border-radius: 4px;
    width: auto;
}

.buttonType {
    &-dark {
        color: white !important;
        background-color: gray !important;
        width: 75px !important;
        
        &-active {
            width: 75px !important;
            background-color: #1a76c7 !important;
        }
    }

    &-light {
        color: black !important;
        background-color: white !important;
        width: 75px !important;
        
        &-active {
            color: white ;
            width: 75px !important;
            background-color: #1a76c7 !important;
        }
    }
}

.impersonatorDropdown {
    &-dark {
        margin-left: 20px;
        color: white !important;
        background-color: gray !important;
        border-radius: 5px;
    }

    &-light {
        margin-left: 20px;
        color: black !important;
        background-color: white !important;
        border-radius: 5px;
    }
}

.cancelIcon {
    margin-left: 10px;
    color: red;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.checkIcon {
    margin-left: 10px;
    color: green;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.userDiv {
    margin-top: 10px;
    margin-bottom: 10px;

    &-btn-col {
        margin-left: -50px !important;
        margin-right: -10px;
    }

    &-name-col {
        margin-left: -150px !important;
    }

    &-usernamee-col {
        margin-left: -100px !important;
        width: 5px !important;
    }

    &-icon-col {
        margin-left: -130px !important;
    }
}

.nameSpan {
    margin-right: 10px;
}

.lastnameSpan {
    margin-right: 10px;
}

.trashIcon {
    position:  10px 10px 10px 10px !important;
    margin-left: 10px;
    cursor: pointer;
    color: rgb(186, 0, 0);
}

.modalHeader- {
    &-light {
        display: flex;
        flex-direction: row;
        color: black;
    }

    display: flex;
    flex-direction: row;
    flex: 1;
    color: white !important;
}

.modalTitle {
    &-dark {
        color: white;
    }

    &-light {
        color: black;
    }
}

.confirmBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
}

.cancelSpan {
    color: white;
    margin-left: 20px;
    cursor: pointer;
}